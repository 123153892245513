import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import TranslationSpan from 'components/ui/TranslationSpan';
import { TEMPLATE_LINK } from 'constants/constants';
import { DEFAULT_LOCALE } from 'constants/languages';

import { ButtonWrapper, Button } from './styled';

interface CreateQRCodeButtonProps {
  disableLanguagePrefix?: boolean;
}
const CreateQRCodeButton = ({ disableLanguagePrefix = false }: CreateQRCodeButtonProps) => {
  const router = useRouter();

  // FIXME: hack to load /generator faster by shallow navigating from root,
  // need to use Nextjs navigation with prefetch instead, the current way
  // is relying on really hacky react-dom navigation manipulated behaviours
  const onCTAClick = useCallback(
    (_e) => {
      _e.preventDefault();
      _e.stopPropagation();
      router.push(TEMPLATE_LINK + router.asPath);
    },
    [router]
  );
  const { i18n } = useTranslation();

  const langprefix = disableLanguagePrefix || i18n.language === DEFAULT_LOCALE ? '' : `/${i18n.language}`;

  return (
    <ButtonWrapper>
      <a href={langprefix + TEMPLATE_LINK + router.asPath} style={{ textDecoration: 'none' }} rel="nofollow">
        <Button onClick={onCTAClick}>
          <TranslationSpan phrase="qr.page.landing.general.main.cta" />
        </Button>
      </a>
    </ButtonWrapper>
  );
};

export default memo(CreateQRCodeButton);
