import { FAQPageJsonLd } from 'next-seo';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC, memo, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

import { subscriptionApi } from 'api/subscription';
import { FAQTabData } from 'components/Landing/FAQ';
import MainCTA from 'components/Landing/Main/MainCTA';
import Steps from 'components/Landing/Main/Steps';
import { MainContainer } from 'components/Landing/Main/styled';
import { showNotification } from 'config/toast';
import { AUTH_COOKIE_PARAM, LINKS } from 'constants/constants';
import { UserRoleEnum } from 'constants/userAccess';
import LandingLayout from 'containers/layouts/LandingLayout';
import { localStorageService } from 'libs/localStorage/local.storage.service';
import { useAppDispatch } from 'store';
import { LoginData, User } from 'types/auth';
import { getModifiedLocale, getValidErrorMessage } from 'utils';

import { TemplateCategoriesMapType } from '../../api/templateCategories/types';
import { refreshToken } from '../../store/auth/thunks';

const DynamicShowCase = dynamic(() => import('components/Landing/Showcase'));
const DynamicFeatures = dynamic(() => import('components/Landing/Features'));
const DynamicFAQ = dynamic(() => import('components/Landing/FAQ'));
const DynamicHowTo = dynamic(() => import('components/Landing/SEOBlock'));
const DynamicReviews = dynamic(() => import('components/Landing/Reviews'));
const DynamicBlueBlock = dynamic(() => import('components/Landing/BlueBlock'));
const DynamicSubscriptionCanceledPopUp = dynamic(
  () => import('components/CancelSubscription/SubscriptionCanceledPopUp')
);

const LandingContainer: FC<
  PropsWithChildren<{ faqData: FAQTabData[]; expandAllFAQ?: boolean; categoriesMap?: TemplateCategoriesMapType }>
> = ({ children, faqData, expandAllFAQ, categoriesMap }) => {
  const [isCancelPopUp, setIsCancelPopUp] = useState(false);
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { query, replace } = useRouter();

  const params: IntersectionOptions = {
    triggerOnce: true,
    threshold: 0.5,
  };
  const { ref: showCaseRef, inView: showCaseInView } = useInView(params);
  const { ref: featuresRef, inView: featuresInView } = useInView(params);
  const { ref: howToRef, inView: howToInView } = useInView(params);
  const { ref: reviewsRef, inView: reviewsInView } = useInView(params);
  const { ref: faqRef, inView: faqInView } = useInView(params);
  const { ref: blueBlockRef, inView: blueBlockInView } = useInView(params);

  const { cancelSubscriptionRequestId } = query;

  const removeCancelSubscriptionRequestId = useCallback(async () => {
    const url = new URL(window.location.href);

    url.searchParams.delete('cancelSubscriptionRequestId');

    replace(url.toString(), undefined, { shallow: true });
  }, [replace]);

  const closeCancelPopup = useCallback(async () => {
    await removeCancelSubscriptionRequestId();

    setIsCancelPopUp(false);
  }, [removeCancelSubscriptionRequestId]);

  const handleCancelSubscription = useCallback(
    async (cancelSubscriptionRequestId: string) => {
      try {
        await subscriptionApi.cancelPublicSubscription(cancelSubscriptionRequestId);

        setIsCancelPopUp(true);
      } catch (error) {
        showNotification(getValidErrorMessage(error), 'error');

        await removeCancelSubscriptionRequestId();
      }
    },
    [removeCancelSubscriptionRequestId]
  );

  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts !== undefined && parts.length === 2) return parts.pop()?.split(';').shift() || '';
    return '';
  };

  useEffect(() => {
    if (!cancelSubscriptionRequestId) return;

    const user = localStorageService.getUser() as User;

    if (user.role === UserRoleEnum.USER) return;

    handleCancelSubscription(cancelSubscriptionRequestId as string);
  }, [cancelSubscriptionRequestId, handleCancelSubscription]);

  useEffect(() => {
    const asyncRefreshToken = async () => {
      const result = await dispatch(refreshToken(true));
      if (result.meta.requestStatus === 'fulfilled' && result.payload && (result.payload as LoginData).accessToken) {
        window.location.pathname = getModifiedLocale(i18n.language, LINKS.CABINET_QR_CODES);
      }
    };
    const user = localStorageService.getUser() as User;
    const isAuthenticated = !!getCookie(AUTH_COOKIE_PARAM);
    if (!isAuthenticated && user) {
      asyncRefreshToken();
      return;
    }

    if (user.role !== UserRoleEnum.USER || !isAuthenticated) return;
    window.location.pathname = getModifiedLocale(i18n.language, LINKS.CABINET_QR_CODES);
  }, [i18n.language]);

  return (
    <>
      {faqData ? (
        <FAQPageJsonLd
          mainEntity={faqData
            .flatMap((el) => el.items)
            .map((el) => ({
              questionName: el.title,
              acceptedAnswerText: el.description,
            }))}
        />
      ) : null}
      <LandingLayout isLogged={false}>
        <MainContainer>
          <MainCTA />
          <Steps />
          <div ref={showCaseRef}>{showCaseInView && <DynamicShowCase categoriesMap={categoriesMap} />}</div>
          <div ref={featuresRef}>{featuresInView && <DynamicFeatures />}</div>
          <div ref={howToRef}>{howToInView && <DynamicHowTo />}</div>
          <div ref={reviewsRef}>{reviewsInView && <DynamicReviews />}</div>
          <div ref={faqRef}>{faqInView && <DynamicFAQ faqData={faqData} expandAll={expandAllFAQ} />}</div>
          <div ref={blueBlockRef}>{blueBlockInView && <DynamicBlueBlock />}</div>
        </MainContainer>
        {children}
        <DynamicSubscriptionCanceledPopUp open={isCancelPopUp} onClose={closeCancelPopup} isLoading={false} />
      </LandingLayout>
    </>
  );
};

export default memo(LandingContainer);
