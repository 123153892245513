import { FC, memo } from 'react';
import { CommonIconProps } from '../Common/types';

const CheckMark: FC<CommonIconProps> = ({
  size = 20,
  color = 'var(--footer-nav-check-mark-icon-color, currentColor)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color || 'currentColor'}
      viewBox="0 0 14 10"
    >
      <g transform="translate(1041.81 -746.343) rotate(90)">
        <path
          transform="translate(-7765 3179.748)"
          d="M8514.628-2138.583a2.3,2.3,0,0,1,0-3.112l1.716-1.822-4.394-4.667a2.3,2.3,0,0,1,0-3.112,1.987,1.987,0,0,1,2.928,0l5.807,6.168.054.054a2.3,2.3,0,0,1,0,3.109l-3.183,3.38a2,2,0,0,1-1.464.645A2,2,0,0,1,8514.628-2138.583Z"
        />
      </g>
    </svg>
  );
};

export default memo(CheckMark);
