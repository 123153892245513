import { memo, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { useTranslationEditorContext } from 'providers/TranslationEditor';
import TranslationSpan from 'components/ui/TranslationSpan';
import CheckMark from 'components/ui/icons/FooterNav/CheckMark';
import { LandingContentContainer } from 'containers/layouts/LandingLayout/styled';

import {
  Point,
  PointsWrapper,
  PointText,
  Title,
  AppImage,
  MainCTAWrapper,
  CTABLockWrapper,
  AppImageWrapper,
} from './styled';
import CreateQRCodeButton from './CreateQRCodeButton';

const MainCTA: FC = () => {
  return (
    <LandingContentContainer>
      <MainCTAWrapper>
        <CTABLockWrapper>
          <Title>
            <TranslationSpan phrase="qr.page.landing.general.main.title" />
          </Title>
          <Points />
          <CreateQRCodeButton />
        </CTABLockWrapper>
        <AppImageWrapper>
          <AppImageComponent />
        </AppImageWrapper>
      </MainCTAWrapper>
    </LandingContentContainer>
  );
};

const AppImageComponent: FC = memo(() => {
  const { t, i18n } = useTranslation();

  const imageUrls = useMemo(() => {
    if (i18n.language === 'es') {
      return {
        mobile: '/images/landing/es/appPreviewDesktop.png',
        desktop: '/images/landing/es/appPreviewDesktop.png',
      };
    }

    return {
      mobile: '/images/landing/appPreviewMobile.svg',
      desktop: '/images/landing/appPreviewDesktop.svg',
    };
  }, [i18n.language]);

  return (
    <AppImage>
      <source srcSet={imageUrls.desktop} media="(min-width: 1024px)" />
      <Image
        src={imageUrls.mobile}
        alt={t('qr.page.landing.seo.main.imagealt', 'create and customize QR codes')}
        width="375"
        height="228"
        loading="eager"
        layout="responsive"
        fetchPriority="high"
        priority
      />
    </AppImage>
  );
});

const Points: FC = memo(() => {
  const { t, i18n } = useTranslation();
  const { getDataAttributes } = useTranslationEditorContext();

  const keys = useMemo(
    () => ({
      point1: 'qr.page.landing.points.point1',
      point2: 'qr.page.landing.points.point2',
      point3: 'qr.page.landing.points.point3',
    }),
    []
  );

  const text = useMemo(
    () => ({
      point1: t(keys.point1, 'Dynamic and editable QR'),
      point2: t(keys.point2, 'Complete analytics'),
      point3: t(keys.point3, 'Wide variety of designs'),
    }),
    [keys, t]
  );

  const isEnglish = i18n.language === 'en';

  return (
    <PointsWrapper>
      <Point>
        <CheckMark size={14} />{' '}
        <PointText large={isEnglish} {...getDataAttributes(keys.point1)}>
          {' '}
          {text.point1}{' '}
        </PointText>
      </Point>
      <Point>
        <CheckMark size={14} />{' '}
        <PointText large={isEnglish} {...getDataAttributes(keys.point2)}>
          {' '}
          {text.point2}{' '}
        </PointText>
      </Point>
      <Point>
        <CheckMark size={14} />{' '}
        <PointText large={isEnglish} {...getDataAttributes(keys.point3)}>
          {' '}
          {text.point3}{' '}
        </PointText>
      </Point>
    </PointsWrapper>
  );
});

export default memo(MainCTA);
